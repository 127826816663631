<template >
  <vue-plyr >
    <video
      controls
      crossorigin
      playsinline
      :data-poster="require('@/assets/images/logo.png')"
    >
      <source size="1080" :src="file" type="video/mp4" />
    </video>
  </vue-plyr>
</template>
<script>
export default {
  name: "vid",
  props: ["file"],
};
</script>
